<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('receiptTax.receiptTaxInvoiceList') }}</span>
        <CommonAdd v-if="accessRight.includes('create')" :name="$t('receiptTax.receiptTaxInvoice')" pathName="addReceiptTaxInvoice" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else-if="receiptTaxInvoiceList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="receiptTaxInvoiceList.data"
              :items-per-page="limit"
              :page.sync="page"
              :server-items-length="receiptTaxInvoiceList.meta.total"
              style="word-break: break-word"
              :options.sync="pagination"
              item-class="py-4"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :search="search"
              @dblclick:row="redirectOnEditPage"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
            >
              <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="2" class="text-end d-flex">
                      <v-autocomplete
                        hide-details="auto"
                        dense
                        v-model="selectedStatus"
                        :items="statusList"
                        item-text="name"
                        item-value="id"
                        flat
                        return-object
                        single-line
                        :label="$t('receiptTax.field.status')"
                      />
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                    <v-col cols="2" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        hide-details="auto"
                        dense
                        v-model="selectedCustomer"
                        :items="customerList.data"
                        item-text="name_1"
                        item-value="id"
                        clearable
                        flat
                        return-object
                        single-line
                        :search-input.sync="customerSearch"
                        :label="$t('receiptTax.field.customer')"
                      >
                        <template v-slot:append-item>
                          <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            />
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3" class="text-end d-flex">
                      <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="invoice_date"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="40"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            class="me-2"
                            single-line
                            v-model="formattedFromDate"
                            @click:clear="invoice_date=''"
                            :label="$t('receiptTax.from')"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice_date"
                          @change="$refs.startDateMenu.save(invoice_date)"
                        >
                          <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="invoice_validity"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="160"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            single-line
                            v-model="formattedToDate"
                            @click:clear="invoice_validity=''"
                            :label="$t('receiptTax.to')"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="invoice_date ? invoice_date : ''"
                          v-model="invoice_validity"
                          @change="$refs.endDateMenu.save(invoice_validity)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.grand_total`]="props">
                <span>{{ numberWithCommas(props.item.grand_total) }}</span>
              </template>
              <template v-slot:[`item.receipt_tax_invoice_date`]="props">
                <span>{{ props.item.receipt_tax_invoice_date && (profileById.date_format ? formatDateDDMMYYYY(new Date(props.item.receipt_tax_invoice_date).toISOString().substring(0,10)): new Date(props.item.receipt_tax_invoice_date).toISOString().substring(0,10))  }}</span>
              </template>
              <template v-slot:[`item.status`]="props">
                <v-chip
                  :color="getColor(props.item.status)"
                  small
                  dark
                >
                  {{ statusList.filter(x => x.id == props.item.status)[0].name }}
                </v-chip>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{receiptTaxInvoiceList.meta.total}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="receiptTaxInvoiceList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import {formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate} from '@/utils';
import CommonAdd from '@/components/CommonAdd';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: 'ReceiptTaxInvoice',
  components: {
    ListSkeleton,
    CommonAdd
  },
  mixins: [intersectionMixin, searchMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      customerPage: 1,
      customerLimit: 10,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || 'receipt_tax_invoice_date'],
        sortDesc: this.$route.query.order_by ? this.$route.query.sortDec ? [true] : [false] : this.$route.query.sortDec ? [true] : [false],
      },
      selectedCustomer: { id: Number(this.$route.query.custId) || null },
      search: this.$route.query.search || "",
      listSkeleton: false,
      startDateMenu: false,
      endDateMenu: false,
      invoice_date: this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0,10),
      invoice_validity: this.$route.query.endDate || new Date().toISOString().substring(0,10),
      selectedStatus: {
        id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'cancelled' ? 2 : this.$route.query.status === 'open' ? 0 : null
      },
    }
  },
  computed: {
    formattedFromDate () {
      return this.profileById.date_format ? this.formatDateDDMMYYYY(this.invoice_date) : parseDateYYYYMMDD(this.invoice_date)
    },
    formattedToDate() {
      return this.profileById.date_format ? this.formatDateDDMMYYYY(this.invoice_validity) : parseDateYYYYMMDD(this.invoice_validity)
    },
    statusList() {
      return [
        { name: this.$t('receiptTax.field.all'), id: null },
        { name: this.$t('receiptTax.field.open'), id: 0 },
        { name: this.$t('receiptTax.field.finished'), id: 1 },
        { name: this.$t('receiptTax.field.canceled'), id: 2 }
      ]
    },
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        customer_id: this.selectedCustomer?.id,
        selectedStatus: this.selectedStatus?.id,
        invoice_date: this.invoice_date,
        invoice_validity: this.invoice_validity,
      };
    },
    ...mapGetters({
      receiptTaxInvoiceList: 'receiptTaxInvoice/receiptTaxInvoiceList',
      customerList: 'customer/customerList',
      accessRight: 'accessRight',
      profileById: 'profile/profileById',
    }),
    headers() {
      return [
        { text: this.$t('documentSeries.docNumber'), align: "start", sortable: true, value: "recipt_tax_invoice_document_number" },
        { text: this.$t('receiptTax.field.title'), value: "title", width: "330px" },
        { text: this.$t('receiptTax.field.customer'), value: "customer_name", sortable: false, width: "160px" },
        this.accessRight.includes('financial') ? { text: this.$t('receiptTax.field.grandTotal'), value: "grand_total" } : '',
        { text: this.$t('receiptTax.field.invoiceDate'), value: "receipt_tax_invoice_date" },
        { text: this.$t('receiptTax.field.status'), value: "status", sortable: false },
      ]
    },
  },
  watch: {
    customerLimit() {
      this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
    },
    params: {
      handler(newVal,oldVal) {
       if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
        this.paginate(false);
      },
      deep: true,
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          this.sortByValue = this.$route.query.order_by || null
          this.sortDescValue = this.$route.query.sortDec ? true : null
          this.selectedCustomer = { id: Number(this.$route.query.custId) || null }
          this.search = this.$route.query.search || ""
          this.invoice_date = this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0,10)
          this.invoice_validity = this.$route.query.endDate || new Date().toISOString().substring(0,10)
          this.selectedStatus = { id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'cancelled' ? 2 : this.$route.query.status === 'open' ? 0 : null }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.commit("receiptTaxInvoice/SET_RECEIPTTAXINVOICE_QUERY", this.$route.query);
    if(Object.keys(this.$route.query).length){
      this.paginate(false);
    }else{
      this.pagination.page = 1
      this.pagination.itemsPerPage = 10
      this.pagination.sortBy = ['receipt_tax_invoice_date']
      this.pagination.sortDesc = [true]
    }
    this.$store.dispatch('profile/GetCompanyById');
    this.$store.dispatch('customer/GetCustomer', {page: this.customerPage, limit: this.customerLimit, order_by: 'name_1|asc'});
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
    getColor(status) {
      if (status == 0) return "orange";
      else if (status == 1) return "green";
      else return "red";
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === 'string' || typeof val === 'object') {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.search = this.search;
      obj.page = this.page;
      obj.status = this.selectedStatus?.id;
      obj.custId = this.selectedCustomer?.id;
      obj.startDate = this.invoice_date;
      obj.endDate = this.invoice_validity;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === 'custId' && val === null) return acc;
        else if (key === "page" && val === 1) return acc;
        else if (key === "startDate" && val === null) return acc;
        else if (key === "endDate" && val === null) return acc
        else if (key === "status"){
          if (val === null) {
            return { ...acc, [key]: 'all'}
          } else if (val === 1) {
            return { ...acc, [key]: 'closed'}
          } else if (val === 2) {
            return { ...acc, [key]: 'cancelled'}
          }  else if (val === 0) {
            return { ...acc, [key]: 'open'}
          } else return acc
        }
        else if (key !== "status" && !val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router.push({
        name: "ReceiptTaxInvoice",
        query: {
          ...obj,
        },
      }).catch(()=>{});
      this.$store.commit("receiptTaxInvoice/SET_RECEIPTTAXINVOICE_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate(isSearch = false) {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
        customer_id,
        selectedStatus,
        invoice_date,
        invoice_validity,
      } = this.params;
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      concateWhereAnd += invoice_date ? "from_receipt_tax_invoice_date," : "";
      concateWhereAnd += invoice_validity ? "to_receipt_tax_invoice_date," : "";
      concateWhereAnd += selectedStatus || selectedStatus == 0 ? "status," : "";
      whereAndVal += invoice_date ? invoice_date + "," : "";
      whereAndVal += invoice_validity ? invoice_validity + "," : "";
      whereAndVal += selectedStatus || selectedStatus == 0 ? selectedStatus + "," : "";
      // end make params for where_and
      
      await this.$store.dispatch("receiptTaxInvoice/GetReceiptTaxInvoice", {
        page: isSearch ? 1 : page,
        limit: itemsPerPage,
        where: customer_id ? "customer_id|" + customer_id : customer_id,
        where_and:
          concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
            ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
            : undefined,
        where_like: this.search
          ? sortBy?.[0] ? sortBy?.[0]+'|'+this.search : sortBy?.[1] ? sortBy[1]+'|'+this.search : sortBy?.[2] ? sortBy?.[2]+'|'+this.search : sortBy?.[3] ? sortBy[3]+'|'+this.search : 'id,title,grand_total,receipt_tax_invoice_date|'+ this.search : 'id,title,grand_total,receipt_tax_invoice_date|',
        order_by: sortBy?.[0]
          ? sortBy?.[0] + ",id|" + (sortDesc?.[0] ? "desc" : "asc") +',desc'
          : "receipt_tax_invoice_date,id|" + (sortDesc?.[0] ? "asc" : "desc") +',desc',
      }).then(()=>this.listSkeleton = false);
    },
    redirectOnEditPage(event, { item }) {
      const path = 'receiptTaxInvoice/receiptTaxInvoiceAction/' + item.id
      this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
    },
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>